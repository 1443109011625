<template>
  <a-modal
    v-model="visible"
    :title="title"
    :mask-closable="false"
    :width="700"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      :rules="addMaterial"
      @submit="handleSubmit"
      v-bind="formItemLayout"
      :loading="loading"
    >
      <a-form-model-item label="类型" prop="category">
        <a-select
          v-model="form.category"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          placeholder="请选择"
          class="w400"
          @change="categoryChange">
          <a-select-option
            v-for="item in typeSelectList"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="title">
        <a-input placeholder="请输入" v-model="form.title" class="w400"/>
      </a-form-model-item>
      <a-form-model-item v-if="form.category" label="示例文件" prop="">
        <uploadFile
          :fileLists="fileLists"
          :isImgUpload="form.category === 'img' || form.category === 'video'"
          :uploadVideo="form.category === 'video'"
          :localFile="form.category === 'cont'"
          :showAdd="true"
          :multiple="true"
          @removed="uploadRemoved"
          @success="uploadSuccess"
        ></uploadFile>
      </a-form-model-item>
      <a-form-model-item label="数量要求" prop="num">
        <a-input-number v-model="form.num"
                        :disabled="!['img'].includes(form.category)"
                        :min="1"/>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="">
        <a-textarea
          v-model="form.desc"
          placeholder="请输入"
          class="w400"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="提交时间" prop="sub_time_type">
        <a-radio-group v-model="form.sub_time_type" @change="changeSubTimeType">
          <a-radio :style="radioStyle" :value="1" style="margin-bottom: 20px;">
            <a-date-picker v-model="form.sub_end_date"
                           :disabled="form.sub_time_type !== 1"
                           :disabled-date="disabledDate"/>
          </a-radio>
          <a-radio :style="radioStyle" :value="2">
            <a-select v-model="days"
                      placeholder="请选择"
                      :disabled="form.sub_time_type !== 2 && form.sub_time_type !== 3">
              <a-select-option v-for="item in timeList"
                               :value="item.value"
                               :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-input-number v-model="form.before_end_days"
                            :disabled="form.sub_time_type !== 2 && form.sub_time_type !== 3"
                            :min="1"
                            class="ml10"/>
            <span class="ml-10">天</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        v-if="trainingType === 2"
        label="选择阶段"
        prop="stage"
      >
        <a-select placeholder="请选择阶段" class="w700" v-model="form.stage">
          <a-select-option v-for="item in stageList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="提交人" prop="sub_role_type">
        <a-radio-group @change="onChangePerson" v-model="form.sub_role_type">
          <a-radio :style="radioStyle" :value="1"> 讲师</a-radio>
          <a-radio :style="radioStyle" :value="2"> 学员</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <template v-if="isStudent">
        <a-form-model-item label="岗位" prop="post_ids">
          <selectJob
            @change="changeTsgw"
            :list="posts"
            v-model="form.post_ids"
            mode="multiple"
          />
        </a-form-model-item>
        <a-form-model-item label="组织机构" prop="org_ids">
          <selectOrg @change="changeTsjg" v-model="form.org_ids"/>
        </a-form-model-item>
      </template>
    </a-form-model>
    <template slot="footer">
      <a-button @click="visible = false">
        取消
      </a-button>
      <a-button :disabled="disabled" type="primary" @click="handleSubmit">
        确认
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import moment from 'moment';
import {getMaterialsDetail, saveMaterials} from '@/api/config';
import {getTrainingPosts} from '../../config/api/api';
import {ConfigMixin} from '../mixins/configMixins';
import {addMaterial} from '../ruleForm';
import {mapState} from 'vuex';
import Common from '@/utils/Common';

export default {
  name: 'addLiveClassLayer',
  mixins: [ConfigMixin],
  props: {
    typeSelectList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    uploadFile: () => import('@/components/upload/uploadFile'),
    selectOrg: () => import('@/components/select/SelectOrg'),
    selectJob: () => import('@/components/select/SelectJob'),
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
      stageList: (state) => state.Offline.stage,
    }),
    formInit() {
      return {
        category: undefined,
        title: '',
        desc: '',
        num: 1,
        before_end_days: 1,
        stage: undefined,
        sub_role_type: undefined,
        sub_time_type: undefined,
        training_id: this.$route.query.id,
      };
    },
  },
  watch: {
    visible(newValue) {
      if (newValue && this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
    },
    trainingType: {
      immediate: true,
      handler(type) {
        if (type === 2) {
          this.addMaterial.stage = [{ required: true, message: '请选择阶段!' }];
        } else if (this.addMaterial.stage) {
          delete this.addMaterial.stage;
        }
      },
    },
  },
  mounted() {
    this.getPosts();
  },
  data() {
    return {
      addMaterial: addMaterial,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      form: { ...this.formInit },
      timeList: [
        {
          value: 2,
          label: '培训结束前',
        },
        {
          value: 3,
          label: '培训结束后',
        },
      ],
      posts: [],
      visible: false,
      loading: false,
      isStudent: false,
      isChangePerson: false, // 提交人是否是讲师
      days: undefined,
      fileLists: [],
      title: '添加回传项',
      disabled: false,
    };
  },
  methods: {
    categoryChange() {
      setTimeout(() => {
        this.form.num = 1;
      }, 0);
    },
    async getPosts() {
      const { data } = await getTrainingPosts({
        training_id: this.$route.query.id,
      });
      this.posts = data;
    },
    changeType(v) {
      this.form.sub_time_type = v;
    },
    moment,
    openMe(data) {
      this.visible = true;
      this.title = data && data.id ? '编辑回传项' : '添加回传项';
      if (data && data.id) {
        this.form.training_id = data.id;
        this.getData(data.id);
        return;
      }
      this.fileLists.length = 0;
      this.form = { ...this.formInit };
      this.days = undefined;
      this.isStudent = false;
      this.isChangePerson = false;
    },
    async getData(id) {
      this.loading = true;
      const ret = await getMaterialsDetail(id).finally(
        () => (this.loading = false),
      );
      if (!ret.data || ret.data.error_code) {
        return;
      }
      this.form = Common.objKeys(ret.data, {
        postids: 'post_ids',
        orgids: 'org_ids',
      });
      this.dataInit(this.form);
    },
    // 编辑操作-数据初始化
    dataInit(data) {
      const fileLists = [];
      const { id, down_title, download_url } = data.eg_file_resource;
      this.addMaterial.org_ids[0].required = data.sub_role_type !== 1;
      this.addMaterial.post_ids[0].required = data.sub_role_type !== 1;
      this.form.sub_end_date = data.sub_end_date
        ? moment(data.sub_end_date * 1000).format('YYYY-MM-DD')
        : null;
      this.isStudent = data.sub_role_type === 2;
      this.days =
        (data.sub_time_type !== 1 &&
          this.timeList.find((item) => item.value === data.sub_time_type)
            .label) ||
        undefined;
      if (+data.sub_time_type === 3) {
        data.sub_time_type = 2;
      }
      download_url &&
      fileLists.push({
        uid: id,
        name: down_title,
        url: download_url,
      });
      this.fileLists = [...fileLists];
    },
    handleSubmit() {
      if (this.isChangePerson) {
        this.form.org_ids = '';
        this.form.post_ids = '';
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    async save() {
      if(this.loading) {
        return;
      }
      this.loading = true;
      this.disabled = true;
      const params = { ...this.form };
      if (+this.days === 3) {
        params.sub_time_type = 3;
      }
      const data = await saveMaterials(params).finally(
        () => (this.loading = false),
      );
      this.visible = false;
      this.disabled = false;
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$emit('success');
      this.$message.success({ content: data?.message || '保存成功' });
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    uploadSuccess(v) {
      this.form.eg_file_id = v.file_resource_id;
    },
    uploadRemoved() {
      this.form.eg_file_id = undefined;
    },
    changeSubTimeType(e) {
      if (e.target.value === 1) {
        this.days = '';
        this.form.before_end_days = 1;
        return;
      }
      this.form.sub_end_date = '';
    },
    onChangePerson(v) {
      this.isStudent = v.target.value === 2;
      this.isChangePerson = v.target.value === 1;
      this.addMaterial.org_ids[0].required = v.target.value === 2;
      this.addMaterial.post_ids[0].required = v.target.value === 2;
    },
    changeTsgw(v) {
      this.form.post_ids = v;
    },
    changeTsjg(v) {
      this.form.org_ids = v;
    },
  },
};
</script>

<style scoped>
.w400 {
  width: 400px;
}

.ml10 {
  margin-left: 10px;
}
</style>
